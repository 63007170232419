/* eslint no-console:0 */
// This file is automatically compiled by Rails-Vite, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%%= vite_javascript_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// See https://github.com/rails/rails/issues/49499
import "@rails/ujs";
// import Rails from "@rails/ujs";
// Rails.start()
// window.Rails = Rails;

import * as Turbo from '@hotwired/turbo';
Turbo.start();

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start();
// Uncomment if you want to use ActionCables
// import "channels";
import "chartkick";
import Highcharts from "highcharts";
window.Highcharts = Highcharts;

import "~/src/global";

import "~/src/i18n";
import "~/src/fontawesome";
import "~/src/select2";
import "~/src/confetti";

import "~/src/index.js.erb";
