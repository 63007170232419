import "bootstrap";

// Debounce function to limit over calling
// https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

$(document).on("turbo:load", function () {
  setTimeout(function () {
    $(".alert").fadeOut();
  }, 30000);
});

// Autocomplete for companies address

$(document).on("turbo:load", function () {
  function initializeAutocomplete(id) {
    var element = document.getElementById(id);
    if (element) {
      var autocomplete = new google.maps.places.Autocomplete(element, {
        types: ["geocode"],
      });
      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        onPlaceChanged,
      );
    }
  }

  function onPlaceChanged() {
    var place = this.getPlace();

    // console.log(place);  // Uncomment this line to view the full object returned by Google API.

    for (var i in place.address_components) {
      var component = place.address_components[i];
      for (var j in component.types) {
        // Some types are ["country", "political"]
        var type_element = document.getElementById(
          "company_" + component.types[j],
        );
        if (type_element) {
          type_element.value = component.long_name;
        }
      }
    }
    const form = document.querySelector("form.edit_company");
    Rails.fire(form, "submit");
  }
  if (typeof google !== "undefined") {
    google.maps.event.addDomListener(window, "load", function () {
      initializeAutocomplete("company_address");
    });
  }
});

// Autocomplete for flights & trains

$(document).on("turbo:load", function () {
  function initializeAutocomplete2(elem) {
    var autocomplete = new google.maps.places.Autocomplete(elem, {
      types: ["(cities)"],
    });
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      var place = autocomplete.getPlace();
      const form = elem.parentElement.parentElement.parentElement;
      Rails.fire(form, "submit");
    });
  }

  if (typeof google !== "undefined") {
    google.maps.event.addDomListener(window, "turbo:load", function () {
      $(".gmaps").each(function (index, elem) {
        initializeAutocomplete2(elem);
      });
    });
  }
});

// Autocomplete for commutes

$(document).on("turbo:load", function () {
  function initializeAutocomplete3(elem) {
    var autocomplete = new google.maps.places.Autocomplete(elem, {
      types: ["geocode"],
    });
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      var place = autocomplete.getPlace();
      const form = elem.parentElement.parentElement.parentElement;
      Rails.fire(form, "submit");
    });
  }

  if (typeof google !== "undefined") {
    google.maps.event.addDomListener(window, "turbo:load", function () {
      $(".gmaps-full").each(function (index, elem) {
        initializeAutocomplete3(elem);
      });
    });
  }
});

// Autocomplete for offices

$(document).on("turbo:load", function () {
  function initializeAutocomplete(id) {
    var element = document.getElementById(id);
    if (element) {
      var autocomplete = new google.maps.places.Autocomplete(element, {
        types: ["geocode"],
      });
      google.maps.event.addListener(
        autocomplete,
        "place_changed",
        onPlaceChanged,
      );
    }
  }

  function onPlaceChanged() {
    var place = this.getPlace();

    // console.log(place);  // Uncomment this line to view the full object returned by Google API.

    for (var i in place.address_components) {
      var component = place.address_components[i];
      for (var j in component.types) {
        // Some types are ["country", "political"]
        var type_element = document.getElementById(
          "office_" + component.types[j],
        );
        if (type_element) {
          type_element.value = component.long_name;
        }
      }
    }
    const form = document.querySelector("form#edit_office_1");
    Rails.fire(form, "submit");
  }
  if (typeof google !== "undefined") {
    google.maps.event.addDomListener(window, "load", function () {
      initializeAutocomplete("survey_office_address");
    });
  }
});

// Submit forms on change on .remote inputs and toggle groups (not .local)

const debouncedSubmitForm = debounce(function (form) {
  Rails.fire(form, "submit");
}, 500);

$(document).on("turbo:load", function () {
  $("input:checked", ".btn-group-toggle").parent().addClass("active");
  $(".remote").on("change keydown", function() {
    debouncedSubmitForm(this.form);
  });
  $(".btn-group-toggle label").not(".local").on("click", function() {
    debouncedSubmitForm(this.form);
  });
  $(".btn-group-toggle label")
    .not(".local")
    .on("click", function () {
      debouncedSubmitForm(this.form);
    });
});

// ACTIVATE QUESTIONS ON SCROLL

// Can also be used with $(document).ready() / $(window).load()
$(document).on("turbo:load", function () {
  // An offset to push the content down from the top
  var listElement = $(".question");
  var offset = $("#header").outerHeight();

  listElement.find('a[href^="#"]').click(function (event) {
    // Prevent from default action to intitiate
    event.preventDefault();

    // The id of the section we want to go to.
    var anchorId = $(this).attr("href");

    // Our scroll target : the top position of the
    // section that has the id referenced by our href.
    if (anchorId.length > 1 && $(anchorId).length > 0) {
      var target = $(anchorId).offset().top - offset;
    } else {
      var target = 0;
    }

    $("html, body").animate({ scrollTop: target }, 250);

    updateQuestionList();
  });

  // Update question elements
  function updateQuestionList(event) {
    const listElement = $(".question");
    const offset = $("#header").outerHeight();

    if (typeof cs === "undefined") {
      return;
    }

    var windowPos = $(window).scrollTop();

    var currentElement = listElement
      .toArray()
      .reverse()
      .find(function (el) {
        var $el = $(el);
        var elTopPosition = $el.position().top;
        var elHeight = $el.height();
        var headerHeight = $("#header").height();

        return (
          elTopPosition - headerHeight - 280 <= windowPos &&
          elTopPosition + elHeight + headerHeight - 280 > windowPos
        );
      });

    var $currentElement = $(currentElement);
    var id = $currentElement.attr("id");
    if (typeof id === "undefined") {
      return;
    }

    // Show every elements if scrolled to bottom
    const documentHeight = document.body.scrollHeight;
    const currentScroll = window.scrollY + window.innerHeight;
    // When the user is [modifier]px from the bottom, fire the event.
    const modifier = 50;
    const can_scroll = currentScroll + modifier <= documentHeight;

    if (!can_scroll) {
      listElement.addClass("active");

      id = listElement.toArray().pop().id;
    } else {
      listElement.removeClass("active");
      $currentElement.addClass("active");
    }
    var trad = "score." + cs + "." + id;

    $("#how_to").html(I18n.t(trad + ".how_to"));
    $("#method").html(I18n.t(trad + ".method"));
    $("#info").html(I18n.t(trad + ".info"));
  }

  // Update menu item on scroll
  $(window).scroll(updateQuestionList);
});

$(document).on("turbo:load", function () {
  var $helpCenterLinks = $(
    "a[href^='https://hellocarbo-25361575.hs-sites-eu1.com/']:not(.nav-link)",
  );
  $helpCenterLinks.attr("title", I18n.t("links_tooltips.help_center"));
  $helpCenterLinks.attr("data-toggle", "tooltip");
  var $commKitLinks = $(
    "a[href^='https://hellocarbo.notion.site/Toolkit-Communication']",
  );
  $commKitLinks.attr("title", I18n.t("links_tooltips.communication_kit"));
  $commKitLinks.attr("data-toggle", "tooltip");
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).on("turbo:load turbo:render", function () {
  const togglePassword = document.getElementById('toggle-password');
  const passwordField = document.getElementById('password');
  const togglePasswordConfirmation = document.getElementById('toggle-password-confirmation');
  const passwordConfirmationField = document.getElementById('password-confirmation');

  function toggleVisibility(passwordField, iconId) {
    const icon = document.getElementById(iconId);
    if (icon) {
      const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';
      passwordField.setAttribute('type', type);

      if (type === 'text') {
        icon.classList.remove('fa-eye');
        icon.classList.add('fa-eye-slash');
      } else {
        icon.classList.remove('fa-eye-slash');
        icon.classList.add('fa-eye');
      }
    }
  }

  if (togglePassword && passwordField) {
    togglePassword.addEventListener('click', function() {
      toggleVisibility(passwordField, 'eye-icon');
    });
  }

  if (togglePasswordConfirmation && passwordConfirmationField) {
    togglePasswordConfirmation.addEventListener('click', function() {
      toggleVisibility(passwordConfirmationField, 'eye-icon-confirmation');
    });
  }
});
